import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import ProjectCategories from '../components/ProjectCategories'

import './BackToWorkPage.css'

export class BackToWorkPageTemplate extends React.Component {
  render() {
    const { sectionProjects, projectCategories = [] } = this.props

    return (
      <div>
        {!!projectCategories && (
          <div className="section backToWork" dataanchor="specialities">
            <div className="wide">
              <div className="title">
                <h5>{sectionProjects.subtitle}</h5>
                <h1>{sectionProjects.title}</h1>
              </div>
              <ProjectCategories categories={projectCategories} from={`landing`}/>
            </div>
          </div>
        )}
      </div>
    )
  }
}

const BackToWorkPage = ({ data: { page, projectCategories } }) => (
  <Layout
    meta={page.frontmatter.meta || false}
    title={page.frontmatter.title || false}
  >
    <BackToWorkPageTemplate
      {...page}
      {...page.fields}
      {...page.frontmatter}
      projectCategories={projectCategories.edges.map(post => ({
        ...post.node,
        ...post.node.frontmatter,
        ...post.node.fields
      }))}
    />
  </Layout>
)

export default BackToWorkPage

export const pageQuery = graphql`
  query BackToWorkPage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      frontmatter {
        sectionProjects {
          subtitle
          title
        }
      }
    }

    projectCategories: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "projectCategories" } }, frontmatter: { parentcategory: { eq: null } } }
      sort: { order: ASC, fields: [frontmatter___order] }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            slug
            sectionOpener {
              image
              mobileImage
            }
          }
        }
      }
    }
  }
`
